export function commons(){
    
    //バーガーメニュー
    $("#HEADER .gnavi").on("click", function () {
        if ($(window).width() < 769) {
            $(this).children("ul").stop().slideToggle(200);
            if ($(this).is('.addClose')) {
                $(this).removeClass('addClose');
            } else {
                $(this).addClass('addClose');
            }
        }
    });
	
    // Aタグクリック時のスクロール
	$('a[href^="#"]').on("click",function() {
		let href= $(this).attr("href");
		let target = $(href == "#" || href == "" ? 'html' : href);
		let position = target.offset().top - $("#HEADER").height();
		$('html,body').animate({scrollTop:position},600);
		return false;
	});

	$('.mdl').modaal({
    	type: 'image'
	});
	
	$(".INDEX.third .navigation").on("click", function () {
        if ($(window).width() < 769) {
            $(this).children("ul").stop().slideToggle(400);
            if ($(this).is('.addClose')) {
                $(this).removeClass('addClose');
            } else {
                $(this).addClass('addClose');
            }
        }
	});
}