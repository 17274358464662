export function index(){
    
    const id = document.getElementById("SWIPE");
    if(id != null){

        //SWIPER
        const swiper = new Swiper('.swiper-container', {
            slidesPerView: 1,
            spaceBetween: 0,
			speed: 800,
			grabCursor: true,
			loop: true,
			parallax: true,
			simulateTouch: false,
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                768:{
                    slidesPerView: 1,
                    spaceBetween: 0
                }
            }
        });
    }
}